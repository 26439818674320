import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSlider: builder.query({ query: () => `slider`, keepUnusedDataFor: 0 }),
        getSliderById: builder.query({ query: (id) => `slider?id=${id}`, keepUnusedDataFor: 0 }),
    })
})

export const {
    useGetSliderQuery,
    useGetSliderByIdQuery,
} = extendedApiSlice
