import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setAuthToken } from '../../redux/authSlice'
import Loading from '../ui/Loading'
import ApiErrorModal from '../modals/ApiErrorModal'
import { useGetUserDetailsQuery } from '../../services/userSlice'
import Api from '../../constants/Api'
import Image from '../ui/Image'
import Icons from '../ui/Icons'

const ProfileDropdown = () => {

    const dispatch = useDispatch()
    const res = useGetUserDetailsQuery()

    const handleSignOut = () => dispatch(setAuthToken(null))



    if (res.isLoading) return <Loading />
    if (res.isError) return <ApiErrorModal response={res} />

    console.log(res.data.profilePicture.id)

    return (
        <div className="dropdown">
            <button className="btn bg-white rounded-pill d-flex  align-items-center  px-3 shadow-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {
                    // res.data.profilePicture.length > 0 &&
                    // <img src={Api.IMAGE_URL + res.data.profilePicture[0]?.id + res.data.profilePicture[0]?.ext} alt="" className='me-3 rounded-circle' width={30} height={30} />
                    <Image
                        src={Api.PROFILE_PICTURE_URL + res?.data?.profilePicture?.id + res?.data?.profilePicture?.ext}
                        alt=""
                        className='me-3 rounded-circle'
                        style={{ width: 30, height: 30 }}
                        icon={() => Icons.defaultUser("", { width: "100%", height: "100%" })}
                    />
                }
                {res.data.name}
            </button>
            <ul className="dropdown-menu">
                <li><NavLink className="dropdown-item" to={"/settings"}>Settings</NavLink></li>
                {
                    res.data.brahmvidyaPortalAccess != null &&
                    <li><Link className="dropdown-item" to={`http://62.72.31.126:3004/token/${res.data.token}`} target="_blank">Go to Brahmvidya Portal</Link></li>
                }

                <li><button className="dropdown-item" onClick={handleSignOut}>Sign Out</button></li>
            </ul>
        </div>
    )
}

export default ProfileDropdown