import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getArchives: builder.query({ query: () => `archive`, keepUnusedDataFor: 0 }),
        // createCategory: builder.mutation({ query: (body) => ({ url: `category`, method: 'POST', body }) }),
    })
})

export const { useGetArchivesQuery } = extendedApiSlice
