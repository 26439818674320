import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCirculars: builder.query({ query: () => `circular`, keepUnusedDataFor: 0 }),
        // createCategory: builder.mutation({ query: (body) => ({ url: `category`, method: 'POST', body }) }),
        markReadCircular: builder.mutation({ query: (body) => ({ url: `circular/markAsRead`, method: 'PATCH', body }) }),
        getMarkReadCircular: builder.query({ query: () => `circular/markAsRead`, keepUnusedDataFor: 0 }),


    })
})

export const { useGetCircularsQuery,useMarkReadCircularMutation,useGetMarkReadCircularQuery } = extendedApiSlice
