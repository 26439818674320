import React from 'react'
import { useGetPolicyByIdQuery, useMarkReadPolicyMutation } from '../services/policySlice'
import { Link, useParams } from 'react-router-dom'
import { useState } from 'react';
import Loading from '../components/ui/Loading'
import ApiErrorModal from '../components/modals/ApiErrorModal'
import { useGetUserDetailsQuery } from '../services/userSlice';
import { useGetMarkReadCircularQuery } from '../services/circularSlice';
import SuccessModal from '../components/modals/SuccessModal';
import { Document, Page } from 'react-pdf';

const ViewPolicy = () => {

    const { id } = useParams()

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const userRes = useGetUserDetailsQuery()


    const [markReadPolicy, markReadPolicyRes] = useMarkReadPolicyMutation()
    const readMarkRes = useGetMarkReadCircularQuery()

    const response = useGetPolicyByIdQuery(id)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const markRead = () => {

        let body = {
            userId: userRes.data.id,
            policyId: id

        }
        markReadPolicy(body)
            .then(() => readMarkRes.refetch())
    }

    if (response.isLoading || userRes.isLoading || readMarkRes.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    return (
        <div className='p-5  h-100'>
            {markReadPolicyRes.isSuccess && <SuccessModal label={"Policy Update!"} message={"Policy is marked as read."} />}

            <div className='d-flex align-items-start justify-content-between'>
                <div className='mb-3'>
                    <h4 className='fw-bold text-danger'>{response.data.title}</h4>
                    <div className='text-muted mb-4' dangerouslySetInnerHTML={{ __html: response.data.content }}></div>
                </div>
                <div>

                    <div className='mb-3'>
                        {
                            readMarkRes.data.filter((itemc) => itemc.policyId == id && itemc.userId == userRes.data.id).length <= 0 ? (
                                <button className="btn btn-success rounded-pill px-3 text-white ms-auto" onClick={markRead}>
                                    Mark as read
                                </button>
                            )
                                :
                                <div className="btn text-white px-3 rounded-pill mx-2 shadow" style={{ backgroundColor: "blue" }} >Marked as read</div>

                        }
                        <Link to={'test'} className="btn text-white px-3 rounded-pill mx-2 shadow" style={{ backgroundColor: "#1F91D0" }}>Take Test</Link>
                    </div>
                    <div>
                        <div className="btn text-white px-3 rounded-pill mx-2 shadow" style={{ backgroundColor: "#EEB217" }}>Queries</div>
                        <div className="btn text-white px-3 rounded-pill mx-2 shadow" style={{ backgroundColor: "#A34D86" }}>Download PDF</div>
                    </div>
                </div>
            </div>


            <div className="  w-100    " style={{height:'75vh'}}>

                {
                    response.data.pdfLink.endsWith('.pdf') ?
                        <object data={`${response.data.pdfLink}#toolbar=0&navpanes=0&scrollbar=0`}
                            width="100%"
                            height="100%">
                        </object>
                        :
                        <div>Invalid URL</div>
                }

            </div>

            {/* {response.data.pdfLink.endsWith('.pdf') ? (
    <iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(response.data.pdfLink)}&embedded=true&rm=embed`} title="PDF Viewer" width="100%" height="500" />
) : (
    <div>Invalid PDF URL</div>
)} */}


        </div>
    )
}

export default ViewPolicy