import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetPolicyByIdQuery } from '../services/policySlice'
import Loading from '../components/ui/Loading'
import ApiErrorModal from '../components/modals/ApiErrorModal'

const TakeTest = () => {

    const { id } = useParams()
    const response = useGetPolicyByIdQuery(id)

    
    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

  return (
    <div className='p-5'>
        <h3 className='fw-bold mb-3'><span className='text-danger'>Take test: </span>{response.data.title}</h3>
        <p>In this test you will be asked questions regarding the pdf "{response.data.title}".
            We hope you have read the associated document keenly.</p>
        <div className='p-3' dangerouslySetInnerHTML={{ __html: response.data.testContent }}></div>
    </div>
  )
}

export default TakeTest