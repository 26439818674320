import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({ query: (body) => ({ url: `education/login`, method: 'POST', body }) }),
        changePassword: builder.mutation({ query: (body) => ({ url: `changePassword`, method: 'PATCH', body }) }),
        // signOut: builder.mutation({ query: (body) => ({ url: `signOut`, method: 'POST', body }) }),
        updateEmailOrContact: builder.mutation({ query: (body) => ({ url: `changeEmailOrContact`, method: 'PATCH', body }) }),
        
    })
})

export const { 
    useLoginMutation, 
    useChangePasswordMutation,
    useUpdateEmailOrContactMutation,
    useSignOutMutation 
} = extendedApiSlice
