import { NavLink, useLocation } from 'react-router-dom'
import logoGif from "../../assets/gif/logo.gif"
import Icons from '../ui/Icons';
import { useGetUserDetailsQuery } from '../../services/userSlice';
import Loading from '../ui/Loading';
import ApiErrorModal from '../modals/ApiErrorModal';


export const sideBarLinks = [
    { to: "/", text: "Dashboard", icon: Icons.dashboard },
    { to: "/circulars", text: "Circulars", icon: Icons.circulars },
    { to: "/school", text: "School", icon: Icons.school },
    { to: "/hostel", text: "Hostel", icon: Icons.hostel },
    { to: "/education", text: "Education", icon: Icons.education },
    { to: "/kanyaGurukul", text: "Kanya Gurukul", icon: Icons.kanyaGurukul },
    { to: "/downloadables", text: "Downloadables", icon: Icons.downloadables },
    { to: "/archives", text: "Archives", icon: Icons.archives },
    { to: "/support", text: "Support", icon: Icons.support },
    { to: "/settingV1", text: "Account Settings", icon: Icons.setting },
];

const Sidebar = () => {

    const { pathname } = useLocation()
    const response = useGetUserDetailsQuery()

    const navItem = (link, access) => {
        const allowList = ["/", "/info", "/settingV1", "/support", "/manageUsers"]
        const shouldRenderLink = allowList.includes(link.to) || access[link.to.replace('/', '')] === true;
        return (shouldRenderLink) ? (
            <li key={link.text} className="nav-item">
                <NavLink to={link.to} className="nav-link d-flex align-items-center">
                    {link.icon('me-2', { width: 20, height: 20 })}
                    {link.text}
                </NavLink>
            </li>
        ) : null;
    };

    if (pathname.startsWith("/singleSignOn")) {
        return <></>
    }
    return (
        <div className="d-none d-lg-flex flex-column flex-shrink-0 bg-primary h-100 shadow" style={{ width: 280, zIndex: 1 }}>

            <div className='d-flex justify-content-center mb-3'>
                <div className="bg-white rounded-bottom-5 p-3">
                    <img src={logoGif} alt="" style={{ width: 150 }} />
                </div>
            </div>

            <div className='overflow-auto py-3'>
                <ul className="nav nav-pills flex-column mb-auto px-3">
                    {response.isLoading ?
                        <Loading />
                        :
                        (response.isError ?
                            <ApiErrorModal response={response} />
                            :
                            sideBarLinks.map((link) => navItem(link, response.data.educationPortalAccess)))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Sidebar