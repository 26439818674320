import React, { useState } from 'react'
import Icons from '../../components/ui/Icons'
import downArrow from '../../assets/images/downarrow.png'
import { useGetDownloadablesQuery } from '../../services/downloadablesSlice';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import { Link } from 'react-router-dom';

const Downloadables = () => {

  const [selectedTab, setSelectedTab] = useState("document");

  const response = useGetDownloadablesQuery()

  const iconClassName = (tab) => {
    switch (tab) {
      case 'document': return "p-3 rounded-3 me-4 cursor-pointer"
      case 'video': return "p-3 rounded-3 me-4 cursor-pointer"
      case 'audio': return "p-3 rounded-3 me-4 cursor-pointer"
      case 'picture': return "p-3 rounded-3 me-4 cursor-pointer"

      default:
        break;
    }
  }

  const iconBackground = (tab) => {
    switch (tab) {
      case 'document': return '#2A73C5'
      case 'video': return '#F5BF03'
      case 'audio': return '#EE38CB'
      case 'picture': return '#78AD46'

      default:
        return 'red'
    }
  }

  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  return (
    <div className='py-4 px-5 d-flex h-100 flex-column hide-scroll overflow-hidden'>

      <div className='d-flex justify-content-between align-items-center'>
        <h2 className='fw-bold mb-3 text-danger'>Downloadables</h2>
      </div>

      <div className='d-flex mb-2'>

        <div className='text-center me-4 position-relative cursor-pointer'>
          <div className='p-3 rounded-3' style={{ backgroundColor: '#2A73C5' }} onClick={() => setSelectedTab("document")}>
            {Icons.document('', { width: 25, height: 25 })}
          </div>
          {selectedTab === "document" &&
            <img src={downArrow} alt="" width={20} />
          }
        </div>

        <div className='text-center me-4 position-relative cursor-pointer'>
          <div className='p-3 rounded-3' style={{ backgroundColor: '#F5BF03' }} onClick={() => setSelectedTab("video")}>
            {Icons.video('', { width: 25, height: 25 })}
          </div>
          {selectedTab === "video" &&
            <img src={downArrow} alt="" width={20} />
          }
        </div>

        <div className='text-center me-4 position-relative cursor-pointer'>
          <div className='p-3 rounded-3' style={{ backgroundColor: '#EE38CB' }} onClick={() => setSelectedTab("audio")}>
            {Icons.audio('', { width: 25, height: 25 })}
          </div>
          {selectedTab === "audio" &&
            <img src={downArrow} alt="" width={20} />
          }
        </div>

        <div className='text-center me-4 position-relative cursor-pointer'>
          <div className='p-3 rounded-3' style={{ backgroundColor: '#78AD46' }} onClick={() => setSelectedTab("picture")}>
            {Icons.picture('', { width: 25, height: 25 })}
          </div>
          {selectedTab === "picture" &&
            <img src={downArrow} alt="" width={20} />
          }
        </div>

      </div>

      {/* <div className="container overflow-auto flex-grow-1">
        <div className='row row-cols-3 gy-5 my-3'>
          {response.data.filter((item) => item.type === selectedTab).map(item =>
            <div key={item.id} className='col'>
              <div className='d-flex align-items-center bg-white rounded-3'>
                <div className={iconClassName(selectedTab)} style={{ backgroundColor: iconBackground(selectedTab) }} onClick={() => window.open(item.link, '_blank')}>{Icons[selectedTab]('', { width: 25, height: 25 })}</div>
                <Link to={item.link} target="_blank" className='text-decoration-none'>
                  <p className='fw-bold'>{item.name}</p>
                  <p className='text-muted'>{item.type}</p>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div> */}

      <div className="overflow-auto flex-grow-1 p-0">
        <div className='row row-cols-4 g-3'>
          {response.data.filter((item) => item.type === selectedTab).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, i) =>
            <div className='col cursor-pointer' key={i}>
              <div className='d-flex align-items-center bg-white shadow rounded-3 p-2' onClick={() => window.open(item.link, '_blank')}>
                <div className={iconClassName(selectedTab)} style={{ backgroundColor: iconBackground(selectedTab) }} >{Icons[selectedTab]('', { width: 25, height: 25 })}</div>
                <Link to={item.link} target="_blank" className='text-decoration-none'>
                  <p className='fw-bold'>{item.name}</p>
                  <p className='text-muted'>{item.type}</p>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>


    </div>
  )
}

export default Downloadables
