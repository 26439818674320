import React from 'react'
import poster from '../../assets/images/circular_poster.png'
import { useGetCircularsQuery, useGetMarkReadCircularQuery, useMarkReadCircularMutation, useUpdateCircularsMutation } from '../../services/circularSlice'
import { Link } from 'react-router-dom'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Loading from '../../components/ui/Loading'
import Api from '../../constants/Api'
import { useGetSliderQuery } from '../../services/sliderSlice'
import Slider from '../../components/ui/Slider'
import SuccessModal from '../../components/modals/SuccessModal'
import { useGetUserDetailsQuery } from '../../services/userSlice'
import FilterContainer from './FilterContainer'


const Circulars = () => {

  const response = useGetCircularsQuery()

  const userRes = useGetUserDetailsQuery()

  const readMarkRes = useGetMarkReadCircularQuery()

  const [updateCircular, updateCircularResponse] = useMarkReadCircularMutation()

  const markRead = (id) => {

    let body = {
      userId: userRes.data.id,
      circularId: id

    }
    updateCircular(body)
      .then(() => readMarkRes.refetch())
  }

  if (response.isLoading || userRes.isLoading || readMarkRes.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  return (
    <div className="container-fluid   h-100  ">
      <div className='h-100 w-100 row p-4 d-flex overflow-hidden hide-scroll'>
        {updateCircularResponse.isSuccess && <SuccessModal label={"Circular Update!"} message={"Circular is marked as read."} closeTo={"/circulars"} />}


        <div className='col-8  h-100 overflow-auto'>
          <Slider type="Circulars" />

          {/* {
          response?.data?.slice(0).reverse().map((item, i) =>
            <div className='p-4 bg-white rounded-4 shadow mb-3' key={i}>

              <div className='d-flex justify-content-between align-items-center mb-3'>
                <h5 className='fw-bold'>{item.title}</h5>
                <div className="btn btn-primary text-white rounded-pill">{new Date(item.createdAt).toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}</div>
              </div>

              <div className='text-muted' dangerouslySetInnerHTML={{ __html: item.content }} />;

              <div className='d-flex justify-content-between mt-3'>
                <Link className="btn btn-secondary rounded-pill px-3" target="_blank" to={item.link}>Download Circular</Link>
                <button className="btn btn-success rounded-pill px-3 text-white" onClick={() => markRead(item.id)}>Mark as read</button>
              </div>

            </div>)
        } */}

          {response?.data?.slice(0).reverse().map((item, i) => (
            <div className='p-4 bg-white rounded-4 shadow mb-3' data-bs-toggle="collapse" data-bs-target={`#collapseContent${i}`} aria-expanded="false" aria-controls={`collapseContent${i}`}  key={i}>

              <div className='d-flex justify-content-between align-items-center mb-3'>
                <h5 className='fw-bold' data-bs-toggle="collapse" data-bs-target={`#collapseContent${i}`} aria-expanded="false" aria-controls={`collapseContent${i}`} >{item.title}</h5>
                <div className="btn btn-primary text-white rounded-pill">{new Date(item.createdAt).toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}</div>
              </div>

              <div className='text-muted overflow-hidden ' data-bs-toggle="collapse" data-bs-target={`#collapseContent${i}`} aria-expanded="false" aria-controls={`collapseContent${i}`} style={{ maxHeight: '1.2em' }} dangerouslySetInnerHTML={{ __html: item.content }} />


              <div className='d-flex justify-content-between mt-3'>
                
                {
                  readMarkRes?.data?.filter((itemc) => itemc.circularId === item.id && itemc.userId === userRes.data.id).length <= 0 && (
                    <button className="btn btn-success rounded-pill px-3 text-white ms-auto" onClick={() => markRead(item.id)}>
                      Mark as read
                    </button>
                  )
                }

              </div>

              {/* Collapsible content */}
              <div className="collapse mt-3" id={`collapseContent${i}`}>
                <div className="card card-body" dangerouslySetInnerHTML={{ __html: item.content }} />
                <Link className="btn btn-light shadow-sm rounded-pill mt-3 px-3" target="_blank" to={item.link}>Download Circular</Link>

              </div>



            </div>
          ))}

        </div>

        <div className="col-4  h-100 ">

         <FilterContainer />

        </div>

      </div>
    </div>
  )
}

export default Circulars
