import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCategories: builder.query({ query: () => `category`, keepUnusedDataFor: 0 }),
        getCategoryById: builder.query({ query: (id) => `category?id=${id}`, keepUnusedDataFor: 0 }),
    })
})

export const { 
    useGetCategoriesQuery,
    useGetCategoryByIdQuery,
 } = extendedApiSlice
