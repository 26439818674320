import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPolicies: builder.query({ query: () => `policy`, keepUnusedDataFor: 0 }),
        getPolicyById: builder.query({ query: (id) => `policy?id=${id}`, keepUnusedDataFor: 0 }),
        markReadPolicy: builder.mutation({ query: (body) => ({ url: `policy/markAsRead`, method: 'PATCH', body }) }),
        
       
    })
})

export const {
    useGetPoliciesQuery,
    useGetPolicyByIdQuery,
    useMarkReadPolicyMutation
} = extendedApiSlice
