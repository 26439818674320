import React from 'react'
import Api from '../../constants/Api'

const ViewArchiveModal = ({ item }) => {
    const { title, content, images } = item
    const modalId = 'ViewArchiveModal' + item.id
    return (
        <>
            <button type="button" className="btn btn-primary px-4 rounded-pill" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                View
            </button>

            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>{title}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-5">
                         


                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="mb-2 ">
                                    {
                                       images.map(i => (Api.BASE_URL + "uploads/images/" + i.id + i.ext)).map((src) =>
                                          <>
                                            <div className="col py-1">
                                                <img src={src} className='object-fit-cover rounded' alt="" style={{ height: 300, width: 500 }} />
                                            </div>
                                          </>
                                        )
                                    }
                                </div>
                            <div className='mt-3' dangerouslySetInnerHTML={{ __html: content }} />
                            </div>


                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewArchiveModal