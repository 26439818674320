import React, { useEffect, useState } from 'react'
import progressImg from '../assets/images/progress2.png'
import dashboardImg from '../assets/images/dashboardimg.png'
import ViewNoticeModal from '../components/modals/ViewNoticeModal'
import { useGetNoticesQuery } from '../services/noticeSlice'
import Loading from '../components/ui/Loading'
import Slider from '../components/ui/Slider'
import { useSelector } from 'react-redux'
import { useGetTechSupportQuery, useGetUserDetailsQuery } from '../services/userSlice'
import { Link } from 'react-router-dom'
import { useGetCircularsQuery, useGetMarkReadCircularQuery } from '../services/circularSlice'
import { useGetPoliciesQuery } from '../services/policySlice'
import CircularProgressBar from '../components/ui/CircularProgressBar'
import CircularProgressBarBig from '../components/ui/CircularProgressBarBig'

const Dashboard = () => {

  const user = useGetUserDetailsQuery()
  const noticeRes = useGetNoticesQuery()

  const markAsReadEntries = useGetMarkReadCircularQuery()

  const circularEntries = useGetCircularsQuery()
  const policyEntries = useGetPoliciesQuery()

  const getTechData = useGetTechSupportQuery()

  const [progress, setProgress] = useState({
    total: 0,
    school: 0,
    hostel: 0,
    circular: 0,
    education: 0,
    kanyaGurukul: 0,
  });



  useEffect(() => {

    // Calculate progress for each category
    const total = circularEntries?.data?.length + policyEntries?.data?.length;
    const school = markAsReadEntries?.data?.filter(entry => ((entry?.policyId && entry?.policy.type == 'SCHOOL') && user.data.id == entry.userId)).length;
    const hostel = markAsReadEntries?.data?.filter(entry => ((entry?.policyId && entry?.policy.type == 'HOSTEL') && user.data.id == entry.userId)).length;
    const circular = markAsReadEntries?.data?.filter(entry => entry.circularId && user.data.id == entry.userId).length;
    const education = markAsReadEntries?.data?.filter(entry => ((entry?.policyId && entry?.policy.type == 'EDUCATION') && user.data.id == entry.userId)).length;
    const kanyaGurukul = markAsReadEntries?.data?.filter(entry => ((entry?.policyId && entry?.policy.type == 'KANYAGURUKUL') && user.data.id == entry.userId)).length;

    // Update state with the calculated progress
    setProgress({ total, school, hostel, circular, education, kanyaGurukul });
  }, [markAsReadEntries.isSuccess, circularEntries.isSuccess, policyEntries.isSuccess]);




  if (noticeRes.isLoading || getTechData.isLoading || user.isLoading || markAsReadEntries.isLoading || circularEntries.isLoading || policyEntries.isLoading) return <Loading />

  return (
    <div className='container-fluid  h-100 d-lg-block d-none'>
      <div className="row h-100 overflow-hidden hide-scroll p-4 ">
        <div className=' col-8 d-flex flex-column overflow-auto h-100'>

          <Slider type="Dashboard" />

          <div className='bg-white p-3 pb-0 rounded-5 shadow container'>

            <div className='d-flex align-items-center  py-2 border-bottom mb-2'>
              <img className="me-3 " src={dashboardImg} style={{ width: 65 }} alt="" />
              <div className='fs-5  '>Welcome to the Shikshan Pravrutti Portal. <br />
                This IMS portal is managed by SGRS CO vidhya vibhag.</div>
            </div>
            <div className='d-flex row bg-whit p-2'>


              <div className='  col-6 '>
                <h4 className='fw-bold fs-1 mb-3'>Support</h4>
                <p className='text-muted'>We are available between</p>
                <p className='fw-bold mb-3'>{getTechData?.data[0]?.title}</p>

                <p className='fw-bold'>Address</p>
                <p className='text-muted mb-3 '>{getTechData?.data[0]?.address}</p>

                <div className='d-flex justify-content-between'>
                  <div><p className='fw-bold'>WhatsApp Us</p>
                    <p className='text-muted mb-3'>{getTechData?.data[0]?.whatsapp}</p></div>
                  <div><p className='fw-bold'>Call Us</p>
                    <p className='text-muted mb-3'>{getTechData?.data[0]?.phone}</p></div>
                  <div> <p className='fw-bold'>Email Us</p>
                    <p className='text-muted mb-3'>{getTechData?.data[0]?.email}</p></div>





                </div>
              </div>
              {/* <div className='w-50 text-center' >
              <img src={progressImg} alt="" style={{ width: 300 }} />
            </div> */}
              <div className='rounded-0 border-0 text-center px-3 col-6 '  >
                <h5 className='text-primary fw-bold fs-5 mb-2'>Awareness Progress Report</h5>

                <div className="d-flex justify-content-around align-items-center">

                  <div className=''>
                    <CircularProgressBarBig progress={progress.total} />
                    <div className="d-flex  align-items-center mt-3  w-100 ">
                      <CircularProgressBar progress={progress.circular} />
                      <p className='text-primary fw-bold'>CIRCULAR</p>
                    </div>
                  </div>

                  <div className=" d-flex flex-column jusify-content-center align-items-center">

                    <div className="d-flex  align-items-center    w-100 ">
                      <CircularProgressBar progress={progress.school} />
                      <p className='text-primary fw-bold'>SCHOOL</p>
                    </div>

                    <div className="d-flex align-items-center   w-100 ">
                      <CircularProgressBar progress={progress.hostel} />
                      <p className='text-primary fw-bold'>HOSTEL</p>
                    </div>

                    <div className="d-flex align-items-center   w-100 ">
                      <CircularProgressBar progress={progress.education} />
                      <p className='text-primary fw-bold'>EDUCATION</p>
                    </div>

                    <div className="d-flex  align-items-center   w-100">
                      <CircularProgressBar progress={progress.kanyaGurukul} />
                      <p className='text-primary fw-bold'>KANYA G.</p>
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div className='col-4  d-flex flex-column h-100 '>

          <div className='card px-5 rounded-5  h-100 d-flex flex-column hide-scroll shadow'>


            <div className="text-center overflow-hidden mb-3  pb-5"><span className='text-white shadow text-center bg-primary rounded-bottom-4 px-5 py-2 mb-3 fs-4'>Notices</span></div>


            <div className="d-flex flex-column overflow-hidden flex-grow-1 ">
              <div className='overflow-auto flex-grow-1 align-items-center justify-content-center'>
                {noticeRes.data.map(item => {
                  const noticeDate = new Date(item.date);
                  const userCreatedAtDate = new Date(user?.createdAt || 0); // Use a default date if user.createdAt is not available


                  const isNoticeNew = noticeDate >= userCreatedAtDate;



                  return isNoticeNew ? (
                    <div key={item.id}>
                      <div className='d-flex align-items-center'>
                        <div className='bg-secondary py-2 px-3 rounded-4 text-white justify-content-center me-3'>
                          <h3>21</h3>
                          <p className='fs-13'>Jun</p>
                        </div>
                        <p className='fw-bold'>
                          {item.title} <ViewNoticeModal id={item.id} />
                        </p>
                      </div>
                      <hr className='text-light' />
                    </div>
                  ) : null;
                })}

              </div>
              <div className='d-flex justify-content-center my-3'>
                <Link className="btn btn-primary rounded-pill px-5 ms-4 mb-3" to={'notice'}>History</Link>
              </div>
            </div>

          </div>

        </div >

      </div>

    </div >
  )

}

export default Dashboard
