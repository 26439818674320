import React from 'react';

const CircularProgressBarBig = ({ progress ,big }) => {
  const radius = 60; // Set the radius of the smaller circle
  const circumference = 2 * Math.PI * radius;
  const progressPercentage = (progress / 100) * circumference;

  const textX = 80; 
  const textY = 80; 
  
  return (
    <svg height="150" width="180" className='z-2'> {/* Adjusted height and width for the smaller circle */}
      <circle
        className="progress-ring-circle"
        stroke="#8888" // Set the color of the progress bar
        strokeWidth="20" // Adjusted thickness for the smaller circle
        fill="transparent"
        r={radius}
        cx="80" 
        cy="80"
      />
      <circle
        className="progress-ring-indicator"
        stroke="#350023" // Set the color of the progress indicator
        strokeWidth="20" // Adjusted thickness for the smaller circle
        fill="transparent"
        r={radius}
        cx="80"
        cy="80"
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: circumference - progressPercentage,
        }}
      />
     
      <text x={textX} y={textY} textAnchor="middle" dy=".3em" fontSize="20" fill="#000" > Total <br />{ Math.round(progress)}%</text>
    </svg>
  );
};

export default CircularProgressBarBig;
