import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserDetails: builder.query({ query: () => ``, keepUnusedDataFor: 0 }),
        // createCategory: builder.mutation({ query: (body) => ({ url: `category`, method: 'POST', body }) }),
        getTerms: builder.query({ query: () => `termsAndConditions`, keepUnusedDataFor: 0 }),
        getTechSupport: builder.query({ query: () => `technicalSupport`, keepUnusedDataFor: 0 }),
        getNotices: builder.query({ query: () => `notice`, keepUnusedDataFor: 0 }),
        getNoticeById: builder.query({ query: (id) => `notice?id=${id}`, keepUnusedDataFor: 0 }),
        getCmsSupport: builder.query({ query: () => `cmsSupport`, keepUnusedDataFor: 0 }),
        getPolicySetting: builder.query({ query: () => `policySetting`, keepUnusedDataFor: 0 }),
        
    })
})

export const { useGetPolicySettingQuery, useGetUserDetailsQuery , useGetCmsSupportQuery, useGetTermsQuery,useGetTechSupportQuery, useGetNoticeByIdQuery, useGetNoticesQuery} = extendedApiSlice
