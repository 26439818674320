import { Route, Routes, } from 'react-router-dom';
import { useEffect, useState } from 'react';


import "./assets/scss/bootstrap.scss"
import "./assets/css/global.css"
import "./assets/css/fonts.css"
import "./assets/css/scrollbar.css"
import "./assets/css/settings.css"

import Layout from './components/layout/Layout';
import Dashboard from './pages/Dashboard';
import Circulars from './pages/circulars/Circulars';
import School from './pages/school/School';
import Hostel from './pages/hostel/Hostel';
import Education from './pages/education/Education';
import KanyaGurukul from './pages/kanyaGurukul/KanyaGurukul';
import Archives from './pages/archives/Archives';
import Downloadables from './pages/downloadables/Downloadables';
import Support from './pages/support/Support';
import { useSelector } from 'react-redux';
import SupportAction from './pages/support/SupportAction';
import Settings from './pages/setting/Settings';
import ViewPolicy from './pages/ViewPolicy';
import { pdfjs } from 'react-pdf';
import TakeTest from './pages/TakeTest';
import Notices from './pages/notice/Notices';
import SettingsV1 from './pages/setting/SettingsV1';
import SingleSignOn from './pages/signIn/SingleSignOn';
import SignInByToken from './pages/signIn/SignInByToken';
import SignIn from './pages/signIn/SignIn';
import SignInHandler from './pages/signIn/SignInHandler';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

function App() {

  const isSignedIn = useSelector(state => state.auth.authToken);

  useEffect(() => { import('bootstrap') }, [])

  return (

    <Routes>

      {
        isSignedIn
          ?
          <Route path='/' element={<Layout />}>

            <Route index element={<Dashboard />} />
            <Route path='singleSignOn' element={<SingleSignOn />} />
            <Route path='token/:token' element={<SignInByToken />} />
            <Route path='Circulars' element={<Circulars />} />

            <Route path='School' >
              <Route index element={<School />} />
              <Route path=':id'>
                <Route index element={<ViewPolicy />} />
                <Route path='test' element={<TakeTest />} />
              </Route>
            </Route>

            <Route path='Hostel' >
              <Route index element={<Hostel />} />
              <Route path=':id'>
                <Route index element={<ViewPolicy />} />
                <Route path='test' element={<TakeTest />} />
              </Route>
            </Route>

            <Route path='Education' >
              <Route index element={<Education />} />
              <Route path=':id'>
                <Route index element={<ViewPolicy />} />
                <Route path='test' element={<TakeTest />} />
              </Route>
            </Route>

            <Route path='KanyaGurukul' >
              <Route index element={<KanyaGurukul />} />
              <Route path=':id'>
                <Route index element={<ViewPolicy />} />
                <Route path='test' element={<TakeTest />} />
              </Route>
            </Route>

            <Route path='Downloadables' element={<Downloadables />} />
            <Route path='Archives' element={<Archives />} />

            <Route path='Support' >
              <Route index element={<Support />} />
            </Route>

            <Route path='notice' >
              <Route index element={<Notices />} />
            </Route>

            <Route path='settingV1' element={<SettingsV1 />} />
            <Route path='Settings' element={<Settings />} />

            <Route path='*' element={<Dashboard />} />

          </Route>
          :
          <Route path='/'>
            <Route index element={<SignIn />} />
            <Route path='token/:token' element={<SignInByToken />} />
            <Route path='*' element={<SignInHandler />} />
          </Route>
      }

    </Routes>


  );

}

export default App;