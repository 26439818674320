import React from 'react'
import poster from '../../assets/images/school_poster.png'
import Icons from '../../components/ui/Icons'
import { useGetPoliciesQuery } from '../../services/policySlice'
import { Link } from 'react-router-dom'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { useGetCategoriesQuery } from '../../services/categorySlice'
import { useGetSliderQuery } from '../../services/sliderSlice'
import Api from '../../constants/Api'
import Slider from '../../components/ui/Slider'
import { useGetCircularsQuery } from '../../services/circularSlice'
import ViewHostelCircular from './ViewHostelCircular'

const Hostel = () => {

  const rowClassName = 'p-3 bg-white rounded-4 my-3 d-flex justify-content-between align-items-center'

  const policyRes = useGetPoliciesQuery()
  const categoryRes = useGetCategoriesQuery()
  const circular = useGetCircularsQuery()

  if (policyRes.isLoading || categoryRes.isLoading) return <Loading />
  if (policyRes.isError) return <ApiErrorModal response={policyRes} />
  if (categoryRes.isError) return <ApiErrorModal response={categoryRes} />

  return (
    <div className='h-100 container-fluid '>

      <div className="row ps-2 overflow-hidden hide-scroll h-100">
        <div className="py-4 col-8 px-4 overflow-auto h-100">
          <Slider type="Hostel" />
          <h3 className='fw-bold text-danger mb-2'>Hostel Policies</h3>
          <p className='mb-4 fs-5'>The list of hostel polices to be followed by SGRS branches </p>

          {
            categoryRes.isSuccess && categoryRes.data.filter(item => item.type === "HOSTEL").map(item =>
              <div>
                <h4 className='fw-bold text-danger mb-2'>{item.title}</h4>
                <div className="row row-cols-2 g-3 mb-4">
                  {
                    policyRes.isSuccess && policyRes.data.filter(item2 => (item2.category.title === item.title && item2.type === "HOSTEL")).map(item3 =>
                      <div className="col" key={item3.id}>
                        <div className="shadow p-3 bg-white rounded-4 d-flex justify-content-between align-items-center">
                          <p className='fw-bold'>{item3.title}</p>
                          <Link to={'' + item3.id} className='btn btn-outline-success border-0 p-0 rounded-circle text-hover-white '>{Icons.arrow()}</Link>
                        </div>
                      </div>
                    )
                  }
                </div>

              </div>
            )
          }
        </div>

        <div className="col-4  px-0 ps-3 py-4 h-100">


          <div className='overflow-hidden h-100 mb-4 bg-white rounded-start-5 d-flex flex-column shadow-lg'>
            <h4 className=' p-4 text-danger fw-bold text-center' style={{ backgroundColor: "#EDEDED" }}>Circulars related to Hostel Policies</h4>
            <div className=' flex-grow-1'>
              {
                circular?.data?.filter((item) => item.hostelManager)?.map((item) =>
                  <div key={item.id} className='p-3 border-bottom'>
                    <div data-bs-toggle="modal" data-bs-target={"#" + 'ViewHostelCircular' + item.id} >{item.title}</div>

                    <ViewHostelCircular item={item} />

                    <div className="btn"></div>

                  </div>
                )
              }
            </div>


          </div>

        </div>

      </div>
    </div>
  )
}

export default Hostel
