import React from 'react';

const CircularProgressBar = ({ progress ,big }) => {
  const radius = 20; // Set the radius of the smaller circle
  const circumference = 2 * Math.PI * radius;
  const progressPercentage = (progress / 100) * circumference;

    const textX = 30; 
  const textY = 30; 
  
  return (
    <svg height="60" width="60" className=' '> {/* Adjusted height and width for the smaller circle */}
      <circle
        className="progress-ring-circle"
        stroke="#8888" // Set the color of the progress bar
        strokeWidth="7" // Adjusted thickness for the smaller circle
        fill="transparent"
        r={radius}
        cx="30" 
        cy="30"
      />
      <circle
        className="progress-ring-indicator"
        stroke="#350023" // Set the color of the progress indicator
        strokeWidth="7" // Adjusted thickness for the smaller circle
        fill="transparent"
        r={radius}
        cx="30"
        cy="30"
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: circumference - progressPercentage,
        }}
      />
       <text x={textX} y={textY} textAnchor="middle" dy=".3em" fontSize="16" fill="#000" >{ Math.round(progress)}%</text>
    </svg>
  );
};

export default CircularProgressBar;
