import React from 'react'
import Calendar from 'react-calendar'

const FilterContainer = () => {
    return (
        <div className='overflow-hidden h-100 mb-4 bg-white rounded-5 d-flex flex-column shadow'>
            <div className="container p-3 h-100">

                <div className="row text-center d-flex align-items-center py-3 mb-3 ">
                    <div className="col">
                        <div className='fw-bold fs-13'>Cancel</div>
                    </div>
                    <div className="col">
                        <div className='fw-bold text-secondary fs-5'>Filter</div>
                    </div>
                    <div className="col">
                        <div className='fw-bold fs-13'>Reset</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div>Sort By</div>
                    </div>
                </div>

                <div className="row text-center d-flex mb-3">
                    <div className="col ">
                        <div className='border border-3 border-secondary py-1 rounded-4'>Read</div>
                    </div>
                    <div className="col">
                        <div className='border border-3 border-secondary py-1  rounded-4'>Unread</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div>Category</div>
                    </div>
                </div>

                <div className="row text-center d-flex mb-3 fs-13 align-items-center">
                    <div className="col">
                        <div className='bg-light p-2 rounded-4'>School</div>
                    </div>
                    <div className="col">
                        <div className='bg-light p-2 rounded-4'>Hoster</div>
                    </div>
                    <div className="col">
                        <div className='bg-light p-2 rounded-4'>Education</div>
                    </div>
                    <div className="col">
                        <div className='bg-light p-2 rounded-4'>Kanya Gurukul</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="card overflow-hidden rounded-4">
                            <Calendar />
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default FilterContainer