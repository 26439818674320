import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotices: builder.query({ query: () => `notice`, keepUnusedDataFor: 0 }),
        getNoticeById: builder.query({ query: (id) => `notice?id=${id}`, keepUnusedDataFor: 0 }),
    })
})

export const { 
    useGetNoticesQuery,
    useGetNoticeByIdQuery
 } = extendedApiSlice
